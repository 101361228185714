/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1f1f1f;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1f1f1f;
}


body {
  overflow-x: hidden;
}